import React from "react";
import { graphql } from "gatsby";
import ConsultDoctor from "../../../components/consult-doctor/index.jsx";
import { useLocation } from "@reach/router";

export const query = graphql`
    query ConsultDoctor3($slug: String!, $language: String!) {
        allContentfulDoctorProfile(
            filter: { slug: { eq: $slug }, node_locale: { eq: $language } }
        ) {
            nodes {
                slug
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Consult({ data }) {
    const save = data.allContentfulDoctorProfile.nodes[0].slug.split("/").pop();
    const slug = save.replace(/-/g, "");
    const language = data.locales.edges[0].node.language
    
    return <ConsultDoctor slug={slug} language={language}></ConsultDoctor>;
}
